import { useSelector } from '@datagrid/state';

import type { BackendTypes } from '@tf/api';
import { S } from '@tf/utils';

import { appStore } from '@/core/stores';

export const useGetEntityLabel = () => {
	const defs = useSelector(() => appStore.defs.get());

	return (entityKind: BackendTypes.EntityKind) =>
		defs.entities[entityKind]?.label ?? S.prettify(entityKind);
};
