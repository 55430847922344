import { useCallback } from 'react';

import { useAuth, useClerk } from '@clerk/clerk-react';
import { useSelector } from '@datagrid/state';
import * as Sentry from '@sentry/react';
import { decodeJwt } from 'jose';
import type { JWTPayload } from 'jose/dist/types/types';

import type { BackendTypes } from '@tf/api';

import { configStore } from '@/core/stores';

interface TokenResult {
	value: string;
	tenant: string;
	userId: string;
}

type JWTMembershipMetadata = JWTPayload & {
	org_name: string;
	org_member_metadata: { capabilities: BackendTypes.RoleCapabilityValue[] };
};

const hasMembershipMetadata = (claims: JWTPayload): claims is JWTMembershipMetadata => {
	if (typeof claims['org_name'] !== 'string') {
		return false;
	}

	if ('org_member_metadata' in claims) {
		const orgMetadata = claims.org_member_metadata as any;
		if ('capabilities' in orgMetadata) {
			return Array.isArray(orgMetadata.capabilities) && orgMetadata.capabilities.length > 0;
		}
	}

	return false;
};

type Options = {
	withLogger: boolean;
};

export const useGetTenantToken = ({ withLogger }: Options = { withLogger: false }) => {
	const config = useSelector(() => configStore.get());
	const { getToken, signOut, isLoaded, isSignedIn } = useAuth();
	const clerk = useClerk();

	const requiredTenant = import.meta.env.DEV
		? 'local'
		: window.location.hostname.match(/^[\w-]+/)?.[0].toLowerCase();

	const logToSentry = (message: string, context: Record<string, any>): void => {
		if (!withLogger) {
			return;
		}

		Sentry.captureMessage(`GetTenantToken: ${message}`, {
			level: 'error',
			fingerprint: [window.location.hostname, message],
			extra: {
				clerkIsLoaded: isLoaded,
				isSignedIn,
				hostname: window.location.hostname,
				requiredTenant,
				userId: clerk.user?.id,
				userPrimaryEmail: clerk.user?.primaryEmailAddress?.emailAddress,
				...context,
			},
		});
	};

	const getParsedToken = useCallback(async (): Promise<TokenResult | null> => {
		const token = await getToken({ template: config.auth.jwt_template }).catch((error) => {
			logToSentry('Clerk getToken error', {
				jwtTemplate: config.auth.jwt_template,
				error,
			});
			return null;
		});

		if (!token) {
			logToSentry('Clerk returned null token', {
				jwtTemplate: config.auth.jwt_template,
			});
			return null;
		}

		const claims = decodeJwt(token);
		if (!claims.sub || !hasMembershipMetadata(claims)) {
			logToSentry('Wrong JWT claims', {
				jwtTemplate: config.auth.jwt_template,
				claims,
			});
			return null;
		}

		return {
			value: token,
			userId: claims.sub,
			tenant: claims['org_name'],
		};
	}, [config.auth.jwt_template, getToken]);

	return async function retrieveToken() {
		const token = await getParsedToken();

		if (token?.tenant.toLowerCase() === requiredTenant) {
			return token;
		}

		// No token or it belongs to another org, let's try to switch to required org
		const userMemberships = clerk.user?.organizationMemberships;
		const requiredMembership = userMemberships?.find((m) => {
			return m.organization.name.toLowerCase() === requiredTenant;
		});

		if (!requiredMembership) {
			// user is not a member of required organization
			logToSentry('User is not a member of required organization', {
				userMemberships: userMemberships?.map((m) => m.organization.name.toLowerCase()),
			});
			return null;
		}

		await clerk.setActive({ organization: requiredMembership.organization });

		const secondToken = await getParsedToken();
		// Double-check: in rare cases token is still linked to previous tenant after switching
		if (!secondToken || secondToken.tenant.toLowerCase() !== requiredTenant) {
			logToSentry('Invalid token after organization switch, signing out', {
				oldTenant: token?.tenant,
				newTenant: secondToken?.tenant,
				isTokenChanged: token?.value !== secondToken?.value,
			});
			await signOut();
			return null;
		}

		return secondToken;
	};
};
